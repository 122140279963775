.notes_card {
    width: 100%;
    height: 100%;

    .icon-container {
        display: flex;
        padding: auto;
        .edit-note{
            color: #11a0f9;
            @media screen and (max-width: 700px) {
                font-size: 12px;
              }
        }
        .editor-container {
          .editor-icon {
            padding-left: 3px;
            padding-right: 3px;
            color: #11a0f9;
            @media screen and (max-width: 700px) {
              font-size: 12px;
            }
          }
        }
        .save-icon-enable {
            color: #11a0f9;
            font-size: 15px;
            padding: 0 5px;

            @media screen and (max-width: 700px) {
                font-size: 12px;
                padding: 0 3px;
            }
        }

        .save-icon-disable {
            color: rgba(0, 0, 0, 0.2);
            font-size: 15px;
            padding: 0 5px;

            @media screen and (max-width: 700px) {
                font-size: 12px;
                padding: 0 3px;
            }
        }

        .edit-icon-enable {
            color: #11a0f9;
            font-size: 15px;
            padding: 0 5px;

            @media screen and (max-width: 700px) {
                font-size: 12px;
                padding: 0 3px;
                
            }
        }

        .edit-icon-disable {
            color: rgba(0, 0, 0, 0.2);
            font-size: 15px;
            padding: 0 5px;

            @media screen and (max-width: 700px) {
                font-size: 12px;
                padding: 0 3px;
            }
        }

        .delete-icon {
            color: red;
            font-size: 15px;
            padding: 0 5px;

            @media screen and (max-width: 700px) {
                font-size: 12px;
                padding: 0 3px;
            }
        }

        
        .ant-input-group .ant-input-group-addon {
            background-color: red;
        }
      }
    }

    .save-icon-enable {
      color: #11a0f9;
      font-size: 15px;
      padding: 0px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
        padding: 0 3px;
      }
    }
    .save-icon-disable {
      color: rgba(0, 0, 0, 0.2);
      font-size: 15px;
      padding: 0px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
        padding: 0 3px;
      }
    }
    .edit-icon-enable {
       
      color: #11a0f9;
      font-size: 15px;
      padding: 0px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
        padding: 0 3px;
      }
    }
    .edit-icon-disable {
        color: rgba(0, 0, 0, 0.2);
      font-size: 15px;
      padding: 0px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
        padding: 0 3px;
      }
    }
    .delete-icon {
      color: red;
      font-size: 15px;
      padding: 0 5px;
      @media screen and (max-width: 700px) {
        font-size: 12px;
        padding: 0 3px;
      }
    }

    .ant-input-group .ant-input-group-addon {
      background-color: red;
    }


.delete-modal .ant-modal-content {
    width: 80%;
  
    height: 17vh;
    padding: 10px;
    margin: auto;

    @media screen and (max-width: 300px) {
        padding: 3px;
    }

    @media screen and (max-width: 700px)and (min-width: 301px) {
        padding: 10px;

    }

    @media screen and (max-width: 1500px) and (min-width: 701px) {
        height: 20vh;
    }

}

.delete-modal .ant-modal-body {
    height: 15vh;
    width: 100%;

    .ant-modal-confirm-body-wrapper {
        .ant-modal-confirm-body {
            .anticon {
                @media screen and (max-width: 300px) {
                    svg {
                        height: 15px;
                        width: 15px;
                    }
                }

                @media screen and (max-width: 700px)and (min-width: 301px) {
                    svg {

                        height: 15px;
                        width: 15px;
                    }
                }

                @media screen and (max-width: 1500px) and (min-width: 701px) {
                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }
            }

            .ant-modal-confirm-title {
                @media screen and (max-width: 300px) {

                    font-size: 10px;
                }

                @media screen and (max-width: 700px)and (min-width: 301px) {

                    font-size: 10px;
                }

                @media screen and (max-width: 1500px) and (min-width: 701px) {
                    font-size: 15px;
                }
            }

            .ant-modal-confirm-content {
                @media screen and (max-width: 300px) {

                    font-size: 9px;
                    margin: 2px 0px 0px 28px;
                }

                @media screen and (max-width: 700px)and (min-width: 301px) {
                    font-size: 9px;
                    margin: 2px 0px 0px 28px;
                }

                @media screen and (max-width: 1500px) and (min-width: 701px) {
                    margin: 2px 0px 0px 32px;

                }
            }
        }

        .ant-modal-confirm-btns {

            @media screen and (max-width: 700px)and (min-width: 301px) {
                margin: 20px 8px 0px 0px;
            }

            .ant-btn {
                @media screen and (max-width: 300px) {

                    width: 30px;
                    height: 20px;
                    padding: 0;

                    span {
                        font-size: 10px;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                    }
                }

                @media screen and (max-width: 700px)and (min-width: 301px) {
                    width: 30px;
                    height: 20px;
                    padding: 0;

                    span {
                        font-size: 10px;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                    }
                }

                @media screen and (max-width: 1500px) and (min-width: 701px) {
                    width: 30px;
                    height: 20px;
                    padding: 0;

                    span {
                        font-size: 10px;
                        height: 15px;
                        display: flex;
                        justify-content: center;
                    }

                }
            }
        }
    }

   
}

.delete-modal .ant-modal-footer {
    width: 100%;
    height: 25vh;
    padding: 10px 16px;

}

.notes-modal{
 

       
  .ant-modal-content{
      width: 850px;
      height: 680px;
      margin: auto;
      @media screen and (max-width: 300px) {
        width: 200px;
          height: 3%;
          padding: 5px;
      }
      @media screen and (max-width: 700px) and (min-width: 301px){
          width: 290px;
          height: 370px;
          padding: 5px;
       

      }  
      @media screen and (max-width: 1500px) and (min-width: 701px){
          width: 600px;
          padding: 5px;

      }
    }
  }

  .editnotes-modal{
    .ant-modal-content{
        width: 510px;
        height: 680px;
        margin: auto;
        @media screen and (max-width: 300px) {
          width: 200px;
            height: 3%;
            padding: 5px;
        }
        @media screen and (max-width: 700px) and (min-width: 301px){
            width: 290px;
            height: 408px;
            padding: 5px;
         
  
        }  
        @media screen and (max-width: 1500px) and (min-width: 701px){
            width: 600px;
            padding: 5px;
  
        }
      }
    }
  