.left_side_container {
  width: 100%;
  margin-left: 10px;

  overflow: hidden;
  // @media screen and (max-width: 1500px) and (min-width:701px){
  //     height: 100vh;
  //   }

  .card-container {
    height: 69vh;
    overflow: scroll;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0em;
    }
    @media screen and (max-width: 700px) {
      position: relative;
      right: 9px;
      height: 80vh;
    }

    //   @media screen and (max-width: 1500px) and (min-width:701px){
    //     height: 60vh;
    //   }
    .filtereddata {
      width: 100%;
    }
  }

  p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 20px;
    // height: 23px;
    width: 165px;

    @media screen and (max-width: 700px) {
      font-size: 10px;
      padding-left: 5px;
    }

    @media screen and (max-width: 1000px) and (min-width: 701px) {
      font-size: 12px;
      padding-left: 5px;
    }

    @media screen and (max-width: 1500px) and (min-width: 1001px) {
      font-size: 14px;
      padding-left: 5px;
    }
  }

  .notes_card {
    // height: 100%;
    width: 100%;

    .ant-card-body {
      padding: 15px 24px 24px 24px;
    }

    @media screen and (max-width: 700px) {
      //    height: 47%;
      width: 100%;

      .ant-card-body {
        width: 100%;
        // height: 100%;

        padding: 10px;

        .ant-input-affix-wrapper {
          height: 18px;

          .ant-input {
            font-size: 10px;
          }

          .ant-input-suffix {
            font-size: 10px;
          }
        }
      }
    }

    @media screen and (max-width: 1000px) and (min-width: 701px) {
      // height: 62%;
      width: 100%;

      .ant-card-body {
        padding: 10px;

        .ant-input-affix-wrapper {
          height: 26px;

          .ant-input {
            font-size: 10px;
          }

          .ant-input-suffix {
            font-size: 10px;
          }
        }
      }
    }

    .input {
      // height: 100%;
      width: 100%;

      Input {
        text-overflow: ellipsis;

        @media screen and (max-width: 700px) {
          margin: 0px;
          height: 10px;
        }
      }
    }
  }

  .image {
    @media screen and (max-width: 1000px) and (min-width: 701px) {
      width: 300px;
      height: 200px;
      margin-left: 100px;
      padding-top: 100px;
    }

    @media screen and (max-width: 1500px) and (min-width: 1001px) {
      width: 300px;
      height: 200px;
      margin-left: 100px;
      padding-top: 100px;
    }
  }
}
