.right_side_container {
  width: 100%;
  overflow: hidden;

  .cards-container {
    height: 79vh;
    overflow: scroll;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0em;
    }

    @media screen and (max-width: 700px) {
      height: 80vh;
    }

    .cards {
      width: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 0.5em;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .cred_list {
    border-radius: 5px;
    width: 100%;
    margin: 0px;

    .right-bar-header {
      width: 99%;
      height: 30px;
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      .action-tools{
        display: flex;
        gap: 10px;
        align-items: center;
        padding-right: 10px;

        .import-button{
          border: none;
          .ant-btn-icon{
              line-height: 4px !important;
              .anticon{
                position: relative;
                bottom: 3px;
                font-size: 14px;
                display: initial;
              }
            }
      }
      
      }

      p {
        display: inline-flex;
        margin: 0px;
        gap: 4px;
        width: 55%;
        font-size: 24px;
        padding-left: 20px;
        color: rgba(0, 0, 0, 0.4);

        .switch {
          box-shadow: none;
        }
        @media screen and (max-width: 380px) {
          font-size: 10px;
          padding: 0px;
          margin: 5px;
          width: 70%;
        }
        @media screen and (max-width: 700px) and (min-width: 381px) {
          margin: 5px;
          padding: 0px;
          font-size: 12px;
        }
        @media screen and (max-width: 1000px) and (min-width: 701px) {
          margin: 7px;
          padding: 0px;

          font-size: 16px;
        }
        @media screen and (max-width: 1500px) and (min-width: 1001px) {
          margin: 7px;
          padding: 0px;

          font-size: 18px;
        }
      }
    }

    .divider {
      margin: 0px;

      width: 100%;
    }
  }

  .notes_card {
    width: 100%;

    padding: 0px;
    height: 100%;
    .input {
      .save-icon-enable {
        color: #11a0f9;
        font-size: 15px;

        padding: 0px;
        @media screen and (max-width: 700px) {
          font-size: 12px;
          padding: 0px;
        }
      }
      .save-icon-disable {
        color: rgba(0, 0, 0, 0.2);
        font-size: 15px;

        padding: 0px;
        @media screen and (max-width: 700px) {
          font-size: 12px;
          padding: 0px;
        }
      }
      .edit-icon-enable {
        color: #11a0f9;
        font-size: 15px;

        padding: 0px;
        @media screen and (max-width: 700px) {
          font-size: 12px;
          padding: 0px;
        }
      }
      .edit-icon-disable {
        font-size: 15px;

        padding: 0px;
        @media screen and (max-width: 700px) {
          font-size: 12px;
          padding: 0px;
        }
      }
      Input {
        text-overflow: ellipsis;
      }
    }
    @media screen and (max-width: 700px) {
      height: 47%;

      .container {
        height: 30px;
      }
      .ant-card-body {
        padding: 10px;

        .ant-input-affix-wrapper {
          height: 20px;

          .ant-input {
            font-size: 10px;
          }
          .ant-input-suffix {
            font-size: 10px;
          }
        }
      }
    }
    @media screen and (max-width: 1000px) and (min-width: 701px) {
      .ant-card-body {
        padding: 10px;
        .ant-input-affix-wrapper {
          height: 20px;
          .ant-input {
            font-size: 10px;
          }
          .ant-input-suffix {
            font-size: 10px;
          }
        }
      }
    }
  }
  .image {
    display: flex;
    justify-content: center;
    padding-top: 50px;
    @media screen and (max-width: 700px) {
      padding-top: 30px;
    }
    @media screen and (max-width: 1000px) and (min-width: 701px) {
      padding-top: 90px;
    }
    @media screen and (max-width: 1500px) and (min-width: 1001px) {
      padding: 0px;
    }
  }
}

.addnotes-modal {
  padding: 0px;
  .ant-modal-content {
    height: 680px;
    @media screen and (max-width: 700px) {
      width: 69%;
      height: 438px;
      margin: auto;

      padding: 5px 10px;
    }
    .ant-modal-body {
      @media screen and (max-width: 700px) {
        height: 343px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    margin: 0px;
    padding: 0px;
  }
}
