.no_data_default_container {
  padding-top: 150px;
  @media screen and (max-width: 700px) {
    padding-top: 100px;
  }

  .no_notes {
    width: 459px;
    height: 295px;
    @media screen and (max-width: 700px) {
      width: 139px;
      height: 89px;
    }
    @media screen and (max-width: 1000px) and (min-width: 701px) {
      width: 300px;
      height: 200px;
    }
    @media screen and (max-width: 1500px) and (min-width: 1001px) {
      width: 400px;
      height: 300px;
    }
  }
}
