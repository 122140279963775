.select-container {
  text-align: left;
  .ant-select{
    width: 152px;
  }
  .ant-btn-default {
    width: 142px;
  }
  .footer-container{
    margin-top: 5px;
  }
}
.select-option-with-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
