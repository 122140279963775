.editnote-form {
  h2 {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px;
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 700px) {
    height: 382px;
    padding: 10px 5px;
  }
  .editnote-form-item {
    margin: 0px;
    height: 70px;
    @media screen and (max-width: 700px) {
      height: 40px;
    }

    .ant-form-item-row {
      flex-direction: unset;
      @media screen and (max-width: 700px) {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              height: 30px;
            }
          }
        }
      }

      .ant-form-item-explain-error {
        font-size: 12px;
        height: 15px;
        @media screen and (max-width: 700px) {
          font-size: 8px;
          height: 10px;
          margin-top: -12px;
        }
      }
      .ant-form-item-label {
        padding-bottom: 0px;
        @media screen and (max-width: 700px) {
          height: 18px;
        }
      }
    }

    label {
      font-size: 14px;
      color: #5b5656;
      @media screen and (max-width: 700px) {
        font-size: 10px;
        height: 12px;
      }
    }

    .editnote-input {
      background: #f8f8f8;
      border-radius: 10px;
      width: 100%;
      height: 30px;
      @media screen and (max-width: 700px) {
        height: 18px;

        font-size: 10px;
      }
    }
  }
  .editnote-form-item-password {
    margin: 0px;
    height: 70px;
    @media screen and (max-width: 700px) {
      height: 50px;
      .ant-form-item-control {
        // height: 30px;
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            height: 30px;
          }
        }
      }
    }

    .ant-form-item-explain {
      font-size: 12px;
      @media screen and (max-width: 700px) {
        font-size: 8px;
        margin-top: -15px;
      }
    }
    @media screen and (max-width: 700px) {
      height: 45px;
    }
    .ant-form-item-label {
      padding: 0px;
    }
    label {
      @media screen and (max-width: 700px) {
        font-size: 10px;
        height: 12px;
      }
    }
    .editnote-input-password {
      background: #f8f8f8;

      border-radius: 10px;
      width: 100%;
      height: 30px;
      @media screen and (max-width: 700px) {
        height: 18px;

        .ant-input {
          font-size: 10px;
        }
        .ant-input-suffix {
          font-size: 10px;
        }
      }
    }
  }
  .editnote-editor {
    @media screen and (max-width: 700px) {
      margin: 0px;
      height: 125px;
    }
    .ant-form-item-explain {
      font-size: 12px;
      height: 15px;
      @media screen and (max-width: 700px) {
        font-size: 8px;
        height: 10px;
        margin-top: -2px;
      }
    }
    .ant-form-item-label {
      padding: 0px;
    }
    label {
      @media screen and (max-width: 700px) {
        font-size: 10px;
        height: 12px;
      }
    }
    .editor {
      .ql-toolbar {
        border-radius: 10px;
        background: #f8f8f8;
        @media screen and (max-width: 700px) {
          padding: 0px;
          height: 45px;
          .ql-formats {
            margin-right: 0px;

            height: 20px;
            .ql-font {
              width: 60px;

              .ql-picker-label {
                font-size: 10px;
                width: 65px;

                padding: 0px;
              }
            }
            .ql-header {
              width: 50px;
              .ql-picker-label {
                padding: 0px;
                font-size: 10px;
                width: 55px;
              }
            }
            .ql-bold {
              width: 15px;
              height: 18px;

              padding-top: 4px;
            }
            .ql-italic {
              width: 15px;
              height: 18px;

              padding-top: 4px;
            }
            .ql-underline {
              width: 15px;
              height: 18px;

              padding-top: 4px;
            }
            .ql-strike {
              width: 15px;
              height: 18px;

              padding-top: 4px;
            }

            .ql-color-picker {
              .ql-picker-label {
                padding-top: 2px;
                width: 17px;
                height: 18px;
              }
            }
            .ql-blockquote {
              width: 15px;
              height: 18px;
              padding-top: 4px;
            }
            .ql-code-block {
              width: 15px;
              height: 18px;
              padding-top: 4px;
            }
            .ql-align {
              width: 18px;
              height: 18px;
              padding-top: 5px;
              display: inline;
            }
            .ql-list {
              width: 15px;
              height: 15px;
            }
            .ql-indent {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-link {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-image {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
          }
        }
      }
      .ql-container {
        border-radius: 10px;
        background: #f8f8f8;
        height: 160px;

        .ql-editor {
          p {
            font-size: 14px;
            @media screen and (max-width: 700px) {
              font-size: 10px;
            }
          }
        }
        @media screen and (max-width: 700px) {
          height: 50px;
        }
      }
    }
  }
  .editnote-button {
    .button {
      width: 50%;
      @media screen and (max-width: 700px) {
        height: 30px;
      }
      height: 50px;

      background-color: black;
      border-radius: 50px;
      margin: auto;
      display: flex;

      p {
        color: white;
        margin: auto;
        @media screen and (max-width: 700px) {
          font-size: 10px;
        }
      }
    }
    .button:hover {
      color: initial;
      background-color: black !important;
      border-color: initial;
    }
  }
}
