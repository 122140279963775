.main-layout {
  margin-top: 80px;
  .form-container {
    width: 30%;

    @media screen and (max-width: 700px) {
      width: 90%;
      padding: 0px;
    }

    @media screen and (max-width: 1500px) and (min-width: 701px) {
      width: 50%;
    }

    .signin-form {
      padding: 0px 0px 0px 60px;
      width: 100%;

      .ant-form-item-label {
        padding: 0px;
      }

      h1 {
        color: #5b5656;
        font-size: 32px;
        
        @media screen and (max-width: 1500px) {
          display: inline;
          width: 30%;
          margin: 0px;
          font-size: 30px;
        }
      }

      .signin-form-item {
        label {
          font-size: 14px;
          color: #5b5656;
        }

        .inputField {
          background: #f8f8f8;
          border-radius: 10px;
          height: 40px;
        }

        .inputPasswordField {
          background: #f8f8f8;

          border-radius: 10px;

          height: 40px;
        }
      }

      .checkbox-container {
        height: 10px;
        width: 200px;
        display: inline-flex;
        align-items: center;

        p {
          margin: 0px;
        }
      }

      .forgot-password {
        float: right;
        height: 32px;
      }

      .form-button {
        .button {
          width: 50%;
          display: block;
          margin: auto;
          height: 100%;

          background-color: black;
          border-radius: 50px;

          p {
            color: white;
          }

          @media screen and (max-width: 1500px) {
            height: 50px;

            p {
              margin: 0px;
            }
          }
        }

        .button:hover{
          color: initial;
          background-color: black !important;
          border-color: initial;
        }
      }
    }
  }
}

