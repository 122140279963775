.workspaceSelect-input{
    width: 180px;
}

.ant-select-selection-item{
    button{
        display: none;
    }
}
.select-option-with-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

