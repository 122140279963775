.main-layout {
 
    .reset-form-container {
        margin: auto;
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 80px;
        
        @media screen and (max-width: 700px) {
            width: 60%;
            padding: 50px;
            height: 80vh;
  
      }
      
      @media screen and (max-width: 1500px) and (min-width: 701px) {
          width: 40%;
        }
        
        .reset-password-form {
            padding-top: 40px;
            width: 100%;

  
        @media screen and (max-width: 1500px) {
          padding-top: 0px;
        }
  
        .ant-form-item-label {
          padding: 0px;
        }
  
        h1 {
          color: #5b5656;
          font-size: 32px;
  
          @media screen and (max-width: 1500px) {
            margin: 0px;
            font-size: 25px;
          }
        }
        p{
            @media screen and (max-width: 1500px) {
                margin: 0px;
                font-size: 12px;
              }
        }
        .reset-password-form-item {
          label {
  

            padding: 8px 0px;
            font-size: 14px;
            color: #5b5656;
  
  
          }
  
        //   .inputField {
        //     background: #f8f8f8;
        //     border-radius: 10px;
  
        //     height: 40px;
        //     padding: 20px;
        //   }
  

          .reset-password-input-password {
              background: #f8f8f8;
       
              border-radius: 10px;
              width: 100%;
              height:40px;
             
            }
        }
         
        .form-button {
  
          .button {
            width: 50%;
            display: block;
            margin: auto;
            height: 100%;
  
            background-color: black;
            border-radius: 50px;
  
            p {
              color: white;
  
            }
  
            @media screen and (max-width: 1500px) {
              height: 40px;
  
              p {
                margin: 0px;
              }
            }
  
  
  
          }
          .button:hover{
            color: initial;
            background-color: black !important;
            border-color: initial;
          }
        }
        .signup{
            display: flex;
            justify-content: center;
        }
  
      }
    }
  }