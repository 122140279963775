.search-credentials {
  width: 100%;
  height: 130px;

  @media screen and (max-width: 700px) {
    height: 62px;
  }
  @media screen and (max-width: 1000px) and (min-width: 701px) {
    height: 72px;
  }
  @media screen and (max-width: 1500px) and (min-width: 1001px) {
    height: 100px;
  }
  .label {
    p {
      margin-left: 20px;
      color: rgba(0, 0, 0, 0.4);
      font-size: 24px;
      height: 29.23px;
      width:100%;
      @media screen and (max-width: 380px){
        font-size: 10px;
        margin: 3px;
        height: 20px;
      }
      @media screen and (max-width: 700px) and (min-width : 381px) {
        font-size: 12px;
        margin: 5px;
        height: 20px;
      }
      @media screen and (max-width: 1000px) and (min-width: 701px) {
        font-size: 16px;
        margin: 5px;
        height: 25px;
      }
      @media screen and (max-width: 1500px) and (min-width: 1001px) {
        font-size: 18px;
        margin: 5px;
        height: 30px;
      }
    }
    .divider {
      margin: 0px;
      width: 400px;
    }
  }

  .search {
    width: 100%;
    height: 100%;

    .input {
      margin-left: 20px;
      width: 80.27%;
      height: 47.37px;
      padding-top: 20px;
      @media screen and (max-width: 300px){
        height: 18px;
        width: 105px;
        padding: 10px;
        margin-left: 5px;
      }
      @media screen and (max-width: 700px) and (min-width: 301px) {
        height: 18px;
        width: 130px;
        padding: 10px;
        margin-left: 5px;
      }
      @media screen and (max-width: 1000px) and (min-width: 701px) {
        height: 25px;
        width: 350px;
        padding: 10px;
        margin-left: 5px;
      }
      @media screen and (max-width: 1500px) and (min-width: 1001px) {
        height: 35px;
        width: 500px;
        padding: 10px;
        margin-left: 5px;
      }
      .ant-input-affix-wrapper {
        height: 47.37px;
        border-radius: 10px;
        @media screen and (max-width: 700px) {
          height: 18px;
          border-radius: 5px;
          padding: 1px 5px;
          .ant-input-prefix {
            font-size: 10px;
          }
          .ant-input {
            font-size: 10px;
          }
        }
        @media screen and (max-width: 1000px) and (min-width: 701px) {
          height: 25px;
          border-radius: 5px;
          .ant-input-prefix {
            font-size: 12px;
          }
          .ant-input {
            font-size: 12px;
          }
        }
        @media screen and (max-width: 1500px) and (min-width: 1001px) {
          height: 35px;
          border-radius: 5px;
          .ant-input-prefix {
            font-size: 14px;
          }
          .ant-input {
            font-size: 14px;
          }
        }
      }
    }

    Button {
      border-radius: 15px;

      margin: 20px;
      background-color: #11a0f9;
      width: 9%;
      height: 47.37px;

      @media screen and (max-width: 700px) {
        border-radius: 10px;
        height: 18px;

        margin: 10px 0px 0px 5px;

        width: 40px;

        padding: 0px;
      }
      @media screen and (max-width: 1000px) and (min-width: 701px) {
        border-radius: 10px;
        height: 25px;
        margin: 0px;
        margin-top: 10px;
        width: 54px;
        margin-left: 10px;
      }
      @media screen and (max-width: 1500px) and (min-width: 1001px) {
        border-radius: 10px;
        height: 35px;
        margin: 0px;
        margin-top: 10px;
        width: 62px;
        margin-left: 10px;
      }

      P {
        color: #ffffff;
        font-size: 16px;

        margin: auto;
        width: 100%;

        @media screen and (max-width: 700px) {
          display: flex;

          justify-content: center;
          margin: auto;
          font-size: 8px;
        }
        @media screen and (max-width: 1000px) and (min-width: 701px) {
          display: flex;
          justify-content: center;
          margin: auto;
          font-size: 11px;
        }
        @media screen and (max-width: 1500px) and (min-width: 1001px) {
          display: flex;
          justify-content: center;
          margin: auto;
          font-size: 12px;
        }
      }
    }
  }
}
