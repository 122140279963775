.userdata {
  // padding-top: 2%;
  margin: 0;
  .ant-list .ant-list-item{
    padding: 12px 10px;
  }
  @media screen and (max-width: 600px) and (min-width: 300px) {
    height: 80%;
    padding: 0px;
  }
  .input-user {
    width: 100%;
    height: 7vh;
    border-radius: 0px;
    color: rgb(30, 30, 30, 0.6);
    margin: 0;
    padding: 0 8px;

    @media screen and (max-width: 300px) {
      height: 23px;
      margin: auto;
      padding: 0px 5px;
    }
    @media screen and (max-width: 600px) and (min-width: 300px) {
      height: 23px;
      padding: 0px 5px;
      border: 0.5;
    }
    @media screen and (max-width: 900px) and (min-width: 601px) {
      height: 44px;
    }
    @media screen and (max-width: 1200px) and (min-width: 901px) {
      height: 44px;
    }
    input.ant-input {
      cursor: pointer;
      margin-left: 15px;
      color: rgb(30, 30, 30, 0.6);
      @media screen and (max-width: 300px) {
        font-size: 6px;
        margin: auto;
      }
      @media screen and (max-width: 600px) and (min-width: 300px) {
        font-size: 6px;
        margin: auto;
      }
    }
  }

  .user-icon {
    background-color: #11a0f9;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    color: white;

    @media screen and (max-width: 300px) {
      height: 13px;
      width: 13px;
      font-size: 5px;
    }
    @media screen and (max-width: 600px) and (min-width: 300px) {
      height: 12px;
      width: 12px;
      font-size: 6px;
    }
  }

  .anticon svg {
    margin-top: 5px;
    @media screen and (max-width: 600px) and (min-width: 300px) {
      margin-top: 3px;
    }
  }
}
