.modal-container {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .title-select-container{
    padding-left: 10px;
    .ant-select{
      width: 152px;
    }
  }
  .ant-modal-content {
    width: 87%;
    height: 513px;
    padding: 0px;
    margin: auto;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 0em;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
    @media screen and (max-width: 300px) {
      width: 49%;
      height: 301px;
    }
    @media screen and (max-width: 450px) and (min-width: 301px) {
      width: 59%;
      height: 301px;
    }

    @media screen and (max-width: 600px) and (min-width: 451px) {
      width: 60%;
      height: 300px;
      margin: auto;
    }
    .ant-modal-title {
      color: rgb(0, 0, 0, 0.4);
      font-size: 18px;
      margin-left: 12px;
      margin-top: 10px;
      @media screen and (max-width: 300px) {
        margin-left: 5px;

        font-size: 6px;
      }
      @media screen and (max-width: 600px) and (min-width: 300px) {
        margin-left: 5px;
        font-size: 15px;
        margin-top: 5px;
      }
    }
  }
  .modal-body {
    padding: 2%;
    @media screen and (max-width: 300px) {
      padding: 0px;
      height: 20px;
    }
    @media screen and (max-width: 600px) and (min-width: 300px) {
      padding-top: 0px;
      height: 20px;
    }
    .modal-button {
      height: 44px;
      width: 19%;
      float: right;
      @media screen and (max-width: 300px) {
        display: flex;
        justify-content: center;
        width: 10%;
        height: 15px;
      }

      @media screen and (max-width: 600px) and (min-width: 300px) {
        height: 25px;
      }
      p {
        height: 14.98px;
        margin: auto;
        padding-bottom: 7px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: 300px) {
          font-size: 5px;
        }
        @media screen and (max-width: 600px) and (min-width: 300px) {
          display: flex;
          justify-content: center;
          font-size: 10px;

          display: flex;

          margin: 0;
        }
      }
    }
    .input {
      color: rgb(181, 185, 193, 0.8);
      height: 44px;
      width: 78.5%;

      @media screen and (max-width: 300px) {
        width: 72.5%;
        height: 15px;

        .ant-input {
          font-size: 5px;
        }
        svg {
          margin: auto;
          font-size: 7px;
        }
      }
      @media screen and (max-width: 600px) and (min-width: 300px) {
        border-radius: 6px;
        height: 25px;
        width: 75.5%;
        .ant-input {
          font-size: 12px;
        }
        svg {
          margin: auto;
          font-size: 12px;
        }
      }
    }
  }
}
