body {
  margin: 0;
}

@font-face {
  font-family: "inter-semibold";
  src: url("../assets/fonts/static/static/Inter-SemiBold.ttf")
    format("truetype");
}

@font-face {
  font-family: "inter-regular";
  src: url("../assets/fonts/static/static/Inter-Regular.ttf") format("truetype");
}

p,
a,
label,
span,
h2,
h3 {
  font-family: "inter-regular";
}
h1 {
  font-family: "inter-semibold";
}
::placeholder {
  font-family: "inter-regular";
}
.signup-button {
  button {
    font-family: "inter-regular";
  }
}

.ant-menu-item{
  &-active, &-selected {
    text-decoration: none !important;
    &::after {
      display: none;
  }
   
  }

}
.ant-popover {
  width: 100% !important;
}
.ant-popover .ant-popover-content{

  float: none!important;
}
