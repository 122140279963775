.addnote-form {
  .ant-form-item.switch {
    margin: 0;

    .ant-row.ant-form-item-row {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      .ant-form-item-control {
        width: 30px;
        margin-left: 130px;
      }
    }
    .ant-form-item-label {
      padding: 0;
      color: rgba(0, 0, 0, 0.5) !important;
      label {
        font-size: 12px;
      }
    }
  }
  p {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px;
    @media screen and (max-width: 700px) {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 700px) {
    height: 345px;
  }
  .addnote-form-item {
    margin: 0px;
    height: 70px;
    @media screen and (max-width: 700px) {
      height: 40px;
    }

    .ant-form-item-row {
      flex-direction: unset;
      @media screen and (max-width: 700px) {
        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              height: 30px;
            }
          }
        }
      }

      .ant-form-item-explain-error {
        font-size: 12px;
        height: 15px;
        @media screen and (max-width: 700px) {
          font-size: 8px;
          height: 10px;
          margin-top: -12px;
        }
      }
      .ant-form-item-label {
        padding-bottom: 0px;
        @media screen and (max-width: 700px) {
          height: 18px;
        }
      }
    }

    label {
      font-size: 14px;
      color: #5b5656;
      @media screen and (max-width: 700px) {
        font-size: 10px;
        height: 12px;
      }
    }

    .addnote-input {
      background: #f8f8f8;
      border-radius: 10px;
      width: 100%;
      height: 30px;

      .ant-select-selector {
        font-size: 10px;
        height: 18px;
        align-items: center;
        .ant-select-selection-item {
          height: 18px;
          line-height: 2;
        }
        .ant-select-selection-placeholder{
          line-height: unset;
        }
        .ant-select-selection-search-input {
          height: 18px;
        }
      }
      @media screen and (max-width: 700px) {
        height: 18px;
        font-size: 10px;
      }
    }
  }
  .addnote-form-item-password {
    margin: 0px;
    height: 70px;
    @media screen and (max-width: 700px) {
      height: 50px;
      .ant-form-item-control {
        .ant-form-item-control-input {
          .ant-form-item-control-input-content {
            height: 30px;
          }
        }
      }
    }

    .ant-form-item-explain {
      font-size: 12px;
      @media screen and (max-width: 700px) {
        font-size: 8px;
        margin-top: -15px;
      }
    }
    @media screen and (max-width: 700px) {
      height: 45px;
    }
    .ant-form-item-label {
      padding: 0px;
    }
    label {
      @media screen and (max-width: 700px) {
        font-size: 10px;
        height: 12px;
      }
    }
    .addnote-input-password {
      background: #f8f8f8;

      border-radius: 10px;
      width: 100%;
      height: 30px;
      @media screen and (max-width: 700px) {
        height: 18px;

        .ant-input {
          font-size: 10px;
        }
        .ant-input-suffix {
          font-size: 10px;
        }
      }
    }
  }
  .addnote-editor {
    @media screen and (max-width: 700px) {
      margin-bottom: 10px;
      height: 125px;
    }
    .ant-form-item-explain {
      font-size: 12px;
      height: 15px;
      @media screen and (max-width: 700px) {
        font-size: 8px;
        height: 10px;
        margin-top: -2px;
      }
    }
    .ant-form-item-label {
      padding: 0px;
    }
    label {
      @media screen and (max-width: 700px) {
        font-size: 10px;
        height: 12px;
      }
    }
    .editor {
      .ql-toolbar {
        border-radius: 10px;
        background: #f8f8f8;
        @media screen and (max-width: 700px) {
          padding: 0px;
          height: 45px;
          .ql-formats {
            margin-right: 0px;

            height: 20px;
            .ql-font {
              width: 60px;

              .ql-picker-label {
                font-size: 10px;
                width: 65px;
                padding: 0px;
              }
            }
            .ql-header {
              width: 50px;
              .ql-picker-label {
                padding: 0px;
                font-size: 10px;
                width: 55px;
              }
            }
            .ql-bold {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-italic {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-underline {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-strike {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }

            .ql-color-picker {
              width: 15px;
              height: 15px;
              .ql-picker-label {
                padding: 0px;
              }
            }
            .ql-blockquote {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-code-block {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-align {
              width: 22px;
              height: 25px;
              display: inline;
            }
            .ql-list {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-indent {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-link {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
            .ql-image {
              width: 15px;
              height: 15px;
              padding-bottom: 0px;
            }
          }
        }
      }
      .ql-container {
        border-radius: 10px;
        background: #f8f8f8;
        height: 160px;
        .ql-editor {
          @media screen and (max-width: 700px) {
            padding: 6px;
          }
          p {
            font-size: 14px;
            @media screen and (max-width: 700px) {
              font-size: 10px;
            }
          }
        }

        @media screen and (max-width: 700px) {
          height: 50px;
        }
      }
    }
  }
  .addnote-button {
    .button {
      width: 50%;
      // margin-top: 24px !important;
      @media screen and (max-width: 700px) {
        height: 30px;
      }
      height: 50px;

      background-color: black;
      border-radius: 50px;
      margin: auto;
      display: flex;

      p {
        color: white;
        margin: auto;
        @media screen and (max-width: 700px) {
          font-size: 10px;
        }
      }
    }
    .button:hover {
      color: initial;
      background-color: black !important;
      border-color: initial;
    }
  }
}
