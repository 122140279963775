.signup-form {
  @media screen and (max-width: 950px) and (min-width: 601px) {
    width: 80%;
  }

  @media screen and (max-width: 1500px) and (min-width: 951px) {
    width: 70%;
    padding-left: 120px;
  }
  h1 {
    width: 50%;
    height: 100%;
    margin: 0px;

    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    color: #5b5656;
    @media screen and (max-width: 350px) {
      font-size: 24px;
      padding-top: 50px;
    }
    @media screen and (max-width: 1500px) and (min-width: 351px) {
      font-size: 28px;
      padding-top: 5px;
    }
  }

  .select-form-item {
    margin: 0px;
    font-size: 12px;
    font-family: "inter-regular";

    label {
      font-size: 12px;
      color: #5b5656;
    }
    .ant-form-item-label {
      padding: 0px;
      height: 18px;
    }
    .ant-select-selector {
      width: 266px;
      height: 18px;
      font-size: 10px;
      background: #f8f8f8;
      color: black;
      .ant-select-selection-overflow {
        position: relative;
        bottom: 8px;
        height: 15px;
      }
      .ant-select-selection-item {
        height: 16px !important;
        margin: 0;
      }
      .ant-select-selection-placeholder {
        line-height: 1.5;
      }

      .ant-select-selection-item {
        line-height: 1.5;
      }
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 18px;
        }
      }
    }
  }
  .signup-form-item {
    margin: 0px;
    height: 80px;

    @media screen and (max-width: 350px) {
      padding: 0px;
      height: 70px;
    }
    @media screen and (max-width: 1500px) and (min-width: 351px) {
      height: 70px;
      margin: 0px;
      padding-top: 20px;
    }

    .ant-form-item-row {
      flex-direction: unset;

      .ant-form-item-explain-error {
        font-size: 12px;
      }
    }

    .ant-form-item-label {
      padding-bottom: 0px;
    }

    label {
      font-size: 14px;
      color: #5b5656;
    }

    .signup-input {
      background: #f8f8f8;
      border-radius: 10px;
      width: 100%;
      height: 40px;
      @media screen and (max-width: 1500px) {
        height: 30px;
      }
    }
    .signup-input-password {
      background: #f8f8f8;

      border-radius: 10px;
      width: 100%;
      height: 40px;
      @media screen and (max-width: 1500px) {
        height: 30px;
      }
    }
  }
  .signup-agreement {
    p {
      margin: 0px;

      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      color: #5b5656;

      a {
        color: #306d2b;
        text-decoration: underline;
      }
    }
    @media screen and (max-width: 1500px) {
      height: 30px;
      padding-top: 20px;
      p {
        margin: 0px;
        font-size: 12px;
      }
    }
  }
  .signup-button {
    .button {
      width: 50%;

      align-items: center;
      height: 60px;

      background-color: black;
      border-radius: 50px;
      margin: auto;
      display: flex;

      p {
        color: white;
        margin: auto;
        display: flex;
      }

      @media screen and (max-width: 1500px) {
        width: 60%;
        height: 40px;
      }
    }

    .button:hover {
      color: initial;
      background-color: black !important;
      border-color: initial;
    }
    @media screen and (max-width: 1500px) {
      padding-top: 20px;
    }
  }
}
