 @import "../../../style/global.scss";

 .ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
  .ant-dropdown-menu-item {
    color: rgba(0, 0, 0, 0.4);
    .ant-dropdown-menu-title-content {
      display: inline-flex ;
      align-items: center ;
      gap: 5px;
    }
  }
  .anticon {
    color: #11a0f9;
  }
}

 .header_container{ 
  .header{
    background-color: white;
    width: 100%;
    height: 60px;
    padding: 10px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    .avatar{
      background-color: #11a0f9;
    }

    .ellipsis{
      width: 180px;
      white-space: nowrap;
      margin: 0;
      font-family: "inter-regular";
      font-size: 12px;
      margin-right: 6%;
    }

    .workspace-info{
      width: 180px;
      margin-right: 2%;
      font-family: "inter-regular";
    }

    .accountName-info{
      width: 142px;
      margin-right: 2%;
      font-family: "inter-regular";
    }
    
  }
 }