.edit-user-form {
  h1 {
    width: 50%;
    height: 100%;
    margin: 0px;

    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    display: flex;
    color: #5b5656;
  }

  .select-form-item {
    margin: 0px;
    font-size: 12px;
    font-family: "inter-regular";

    label {
      font-size: 12px;
      color: #5b5656;
    }
    .ant-form-item-label {
      padding: 0px;
      height: 18px;
    }
    .ant-select-selector {
      width: 266px;
      height: 18px;
      font-size: 10px;
      background: #f8f8f8;
      color: black;
      .ant-select-selection-overflow {
        position: relative;
        bottom: 8px;
        height: 15px;
      }
      .ant-select-selection-item {
        height: 16px !important;
        margin: 0;
      }
      .ant-select-selection-placeholder {
        line-height: 1.5;
      }

      .ant-select-selection-item {
        line-height: 1.5;
      }
      .ant-select-selection-search {
        .ant-select-selection-search-input {
          height: 18px;
        }
      }
    }
  }
  .edit-user-form-item {
    margin: 0px;
    height: 80px;

    .ant-form-item-row {
      flex-direction: unset;

      .ant-form-item-explain-error {
        font-size: 12px;
      }
    }

    .ant-form-item-label {
      padding-bottom: 0px;
    }

    label {
      font-size: 14px;
      color: #5b5656;
    }

    .input {
      background: #f8f8f8;
      border-radius: 10px;
      width: 100%;
      height: 40px;
    }
    .input-password {
      background: #f8f8f8;

      border-radius: 10px;
      width: 100%;
      height: 40px;
    }
  }
  .signup-agreement {
    p {
      margin: 0px;

      width: 100%;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      color: #5b5656;

      a {
        color: #306d2b;
        text-decoration: underline;
      }
    }
  }
  .button-container {
    .button {
      width: 50%;

      align-items: center;
      height: 60px;

      background-color: black;
      border-radius: 50px;
      margin: auto;
      display: flex;

      p {
        color: white;
        margin: auto;
        display: flex;
      }
    }

    .button:hover {
      color: initial;
      background-color: black !important;
      border-color: initial;
    }
  }
}
