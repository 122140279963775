.account-form {
  &_cancel-button {
    margin-right: 10px;
  }
}
.account-form-with-style {
  .submit-button {
    margin-left: 90px;
  }
}
