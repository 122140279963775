.add-user {
  .ant-modal-content {
    padding: 0px;
    width: 87%;
    height: 513px;
    margin: auto;

    @media screen and (max-width: 700px) {
      width: 59%;
      height: 376px;
      padding: 0px;
    }
    .ant-modal-body {
      h1 {
        margin: 0px;
        padding-left: 40px;
        padding-top: 20px;
        color: rgb(0, 0, 0, 0.4);
        @media screen and (max-width: 700px) {
          padding-left: 20px;
          font-size: 18px;
          padding-top: 20px;
        }
      }
      .form-container {
        padding: 0px 20px 0px 20px;
        height: 280px;
        .signup-form {
          width: 100%;
          padding-top: 10px;
          @media screen and (max-width: 700px) {
            padding-top: 0px;
          }
          .signup-form-item {
            .ant-form-item-label {
              margin-top: 3px;
              height: 25px;
            }
            margin: 0px;

            @media screen and (max-width: 700px) {
              padding: 0px;
              height: 44px;
              .ant-form-item-control {
                .ant-form-item-control-input {
                  min-height: 18px;

                  .ant-form-item-control-input-content {
                    .signup-input {
                      height: 18px;
                      border-radius: 6px;
                      font-size: 10px;
                    }
                  }
                }
              }
              .ant-form-item-control {
                .ant-form-item-control-input {
                  min-height: 18px;

                  .ant-form-item-control-input-content {
                    height: 18px;
                    .signup-input-password {
                      margin-top: 3px;
                      height: 18px;
                      border-radius: 6px;
                      font-size: 10px;
                    }
                  }
                }
              }

              .ant-form-item-explain-error {
                font-size: 10px;
                height: 14px;
                width: 150px;
              }

              label {
                font-size: 12px;
              }
              .ant-form-item-label {
                margin: 0px;
                height: 18px;
              }
            }
            @media screen and (max-width: 1500px) and (min-width: 701px) {
              padding: 0px;
            }
          }
          .signup-button {
            padding-top: 8px;
            .button {
              height: 50px;
            }

            @media screen and (max-width: 700px) {
              .button {
                height: 25px;
                p {
                  font-size: 10px;
                }
              }
            }
            @media screen and (max-width: 1500px) and (min-width: 701px) {
              padding-top: 5px;
            }
          }
          @media screen and (max-width: 1500px) and (min-width: 701px) {
            padding: 0px;
          }
        }
      }

      @media screen and (max-width: 700px) {
        height: 376px;
      }
    }
  }
}
