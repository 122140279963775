.dashboard {
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100%;
  overflow: hidden;

  .footer {
    font-family: inter-regular;
    font-size: 12px;
    padding-right: 5px;
    text-align: center;
    font-weight: 500;
  }

  .container {
    .left-container {
      background-color: white;
      margin-left: 1%;
      margin-top: 1%;
      max-height: 465px;
      width: 48%;
      border-radius: 5px;
      overflow: hidden;

      .image {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .no_data_default {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 295px;
        width: 459px;
        margin-left: 225px;
        margin-top: 60px;
      }

      .no_data_default {
        display: flex;
        justify-content: center;
      }
    }

    .right-container {
      width: 50%;
      max-height: 465px;
      margin-top: 1%;
      margin-left: 1%;
      background-color: white;
      overflow: hidden;
      border-radius: 5px;
    }

    .copy_icon {
      color: #11a0f9;
    }
    .user_data {
      .user_card {
        border-radius: 0%;

        .ant_input {
          color: #11a0f9;
        }
      }
    }
  }
}
