.export-dropdown {
  border: none;
  
  .ant-btn-icon{
    line-height: 4px !important;
    .anticon{
      position: relative;
      bottom: 3px;
      font-size: 14px;
      display: initial;
    }
  }
}


