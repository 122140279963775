.no_data_found_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  .no_data {
  position: relative;
  top: 80px;
  width: 160px;
  }
}

@media screen and (max-width: 600px) and (min-width: 451px){
  .no_data_found_container {
    .no_data {
      position: relative;
      top: 35px;
    }
  }
}