.custom-icon-container {
    color: #11a0f9;
    text-align: center;
    display: grid;
    outline: none;

    &--label {
      height: auto !important;
      display: block;
      font-size: 10px !important;
    }
  }