.editor {
  height: 100%;
p{
    font-size: 24px;
    color: rgba(0, 0, 0, 0.5);
    margin: 0px;
}
      
            @media screen and (max-width: 700px){
                p{
                    font-size: 14px;
                  }
            }
        
            .quill{
        height:  100%;
        @media screen and (max-width: 700px){
            .ql-toolbar{
                height: 50px;
                padding: 0px;
                .ql-formats{
                    margin: 0px;
                    .ql-font{
                        width: 65px;
                        .ql-picker-label{
                            font-size: 10px;
                            padding: 0px;
                            width: 65px;
                            
                        }
                    }
                }
                .ql-formats{
                    .ql-header{
                        width: 51px;
                        .ql-picker-label
                        { font-size: 10px;
                            padding: 0px;
                            width: 51px;
                        }
                    }
                }
                .ql-formats{
                    .ql-bold{
                        padding: 0px;
                      
                        width: 12px;
                        height: 12px;
                    }
                    .ql-italic{
                        padding: 0px;
                     
                        width: 12px;
                        height: 12px;
                    }
                    .ql-underline{
                        padding: 0px;
                        width: 12px;
                        height: 12px;
                    }
                    .ql-strike{
                        padding: 0px;
                       
                        width: 12px;
                        height: 12px;
                    }
                }
                .ql-formats{
                    .ql-color{
                        width: 17px;
                            height: 17px;
                        .ql-picker-label{
                            padding: 0px;
                           
                        }
                    }
                    .ql-background{
                        width: 17px;
                        height: 17px;
                        .ql-picker-label{
                            padding: 0px;
                       
                        }
                    }
                }
                .ql-formats{
                    .ql-blockquote{
                        padding: 0px;
                        width: 12px;
                        height: 12px;
                    }
                    .ql-code-block{
                        padding: 0px;
                        width: 12px;
                        height: 12px;
                    }
                }
                .ql-formats{
                    .ql-align{
                      
                        width: 15px;
                        height: 15px;
                        margin-top: -4px;
                        .ql-picker-label{
                            padding: 0px;
                        }
                    }
                }
                .ql-formats{
                   
                    .ql-list{
                        width: 15px;
                        height: 15px;
                        padding: 0px;
                    }
                }
                .ql-formats{
                    .ql-indent{
                        width: 15px;
                        height: 15px;
                        padding: 0px;
                    }
                }
                .ql-formats{
                    .ql-link{
                        width: 15px;
                        height: 15px;
                        padding: 0px;
                    }
                    .ql-image{
                        width: 15px;
                        height: 15px;
                        padding: 0px;
                    }
                }
            }
        }
      
        .ql-container{
            height: 470px;
            .ql-editor{
                p{
                    font-size: 18px;
                }
            }
            @media screen and (max-width: 700px){
                height: 250px;
                .ql-editor{
                    p{
                        font-size: 10px;
                    }
                }
            }
        }
            }
            .button{
              
                padding-top: 5px;
                @media screen and (max-width: 700px){
                    padding-top: 3px;
                }         
                                  
                                    .save-button{
                                        background-color: #11a0f9;
                                        border: white;
                                        border-radius: 7px;
                                        height: 40px;
                                        width: 15%;
                                      
                                        @media screen and (max-width: 700px){
                                            height: 30px;
                                            width: 50px;
                    
                                        }
                                        p{
                                            font-size: 14px;
                                            margin: 0px;
                                            color: #FFFFFF;
                                            @media screen and (max-width: 700px){
                                                font-size: 10px;
                                            }
                                        }
                    
                                    }
                                    
                                }
                            }
